/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { Table, Modal, Button } from 'antd';
import { EditOutlined, DeleteOutlined, PlusSquareOutlined } from '@ant-design/icons';

import { DELETE, GET, POST } from '../../../components/Utils/requete';
import { testDroit } from '../../../components/Utils/testDroit';

import SelectInstrument from '../../../components/organism/Instrument&Affectation/select';

import * as fonctionAtelier from '../../../fonctionsRoles/atelier';

const AtelierEnseignants = (props) => {
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    let { id } = useParams();
    const [dataEnseignant, setDataEnseignant] = useState([]);
    const [open, setOpen] = useState({ open: false });
    const [dataRechercheEnseignant, setDataRechercheEnseignant] = useState([]);
    const [instrument, setInstrument] = useState();
    function valueAffectation(value) {
        setInstrument(value);
    }

    async function recupDataAtelier(duplicateID) {
        const responseEnseignant = await POST('/api/ateliers/enseignantsByAteliers', {
            fk_atelier: props.id,
        });
        setDataEnseignant(responseEnseignant.data);
    }
    async function recupDataRechercheEnseignant() {
        const data = { fk_anneeScolaire: props.anneeScolaire, fk_site: props.site };
        const response = await POST('/api/utilisateursAdministratifs/listEnseignantsFromSiteAndAnneeScolaire', data);
        setDataRechercheEnseignant(response.data);
    }
    async function associerEnseignant(idUser, nomUser) {
        if (!instrument) return props.notification({ message: `Vous devez affecter une discipline à l'enseignant` });
        await GET(`/api/ateliers/${id}/attachedEnseignant/${idUser}?idInstrument=${instrument}`);
        if (nomUser) props.notification({ message: `Mme\\Mr ${nomUser} a été ajouté à l'atelier`, status: true });
        setOpen({ open: false });
        setInstrument();
        return recupDataAtelier();
    }
    async function suppressionEnseignant(idUser) {
        await DELETE(`/api/ateliers/${id}/attachedEnseignant/${idUser}`);
        setOpen({ open: false });
        return recupDataAtelier();
    }
    function footerModal() {
        const bouton = [
            <Button danger key="back" onClick={() => setOpen({ open: false, content: '', width: open.width })}>
                Retour
            </Button>,
        ];
        if (open.event !== 'addEnseignant')
            bouton.unshift(
                <Button
                    className="cbleu border1bleu mr2 xs_w90"
                    key="back"
                    onClick={() => {
                        if (open.event === 'supprEnseignant') suppressionEnseignant(open.params);
                        if (open.event === 'editionDiscipline') associerEnseignant(open.params);
                    }}
                >
                    Valider
                </Button>,
            );
        return bouton;
    }
    useEffect(() => {
        recupDataAtelier();
    }, []);
    const columns = [
        {
            render: (text, props) => (
                <div style={{ width: `${(window.innerWidth / 100) * 90}px` }}>
                    <div className="flex spaceBetween borderBottom">
                        <span className="mr5">Enseignant : </span>
                        {testDroit(user[usurpation].roleFonction, fonctionAtelier.Lien_profil_enseignant) ? (
                            <Link to={`/mes-cours/${props.fk_enseignant}`}>{props.nomPrenomUtilisateur}</Link>
                        ) : (
                            props.nomPrenomUtilisateur
                        )}
                    </div>
                    <div className="flex spaceBetween ">
                        <span className="mr5">Discipline : </span>
                        <span>{props.libelleInstrument} </span>
                    </div>
                </div>
            ),
            responsive: ['xs'],
        },
        {
            title: 'Enseignant',
            render: (text, props) => (
                <div className="flex spaceBetween">
                    {testDroit(user[usurpation].roleFonction, fonctionAtelier.Lien_profil_enseignant) ? (
                        <Link to={`/mes-cours/${props.fk_enseignant}`}>{props.nomPrenomUtilisateur}</Link>
                    ) : (
                        props.nomPrenomUtilisateur
                    )}
                    <div className="w30">
                        {testDroit(user[usurpation].roleFonction, fonctionAtelier.Desassocier_enseignant) && (
                            <EditOutlined
                                className="fontSize17 cBleu2c70c6 mr5"
                                onClick={() => setOpen({ open: true, event: 'editionDiscipline', params: props.fk_enseignant, width: '40%' })}
                            />
                        )}
                        {testDroit(user[usurpation].roleFonction, fonctionAtelier.Desassocier_enseignant) && (
                            <DeleteOutlined
                                className="fontSize17 cRed"
                                onClick={() => setOpen({ open: true, event: 'supprEnseignant', params: props.fk_enseignant, width: '25%' })}
                            />
                        )}
                    </div>
                </div>
            ),
            responsive: ['sm'],
        },
        {
            title: 'Discipline',
            dataIndex: 'libelleInstrument',
            responsive: ['sm'],
        },
    ];

    const columnsRecherche = [
        {
            title: 'Enseignant',
            dataIndex: 'nomPrenomUtilisateur',
            render: (text, props) => (
                <div>
                    <PlusSquareOutlined className="cVert fontSize20 mr5" onClick={() => associerEnseignant(props.idUtilisateur, props.nomPrenomUtilisateur)} />
                    {props.nomPrenomUtilisateur}
                </div>
            ),
            responsive: ['sm'],
        },
        {
            title: 'Fixe',
            dataIndex: 'telephoneFixe',
            responsive: ['sm'],
        },
        {
            title: 'Mobile',
            dataIndex: 'telephoneMobile',
            responsive: ['sm'],
        },
    ];

    return (
        <div>
            <Modal
                width={open.width}
                open={open.open}
                onCancel={() => setOpen({ open: false, content: '', width: open.width })}
                closeIcon={false}
                footer={footerModal()}
            >
                {open.event === 'supprEnseignant' && <h3>Voulez-vous supprimer cet enseignant</h3>}
                {open.event === 'editionDiscipline' && (
                    <div>
                        <h3 className="textCenter">Modifier la discipline</h3>
                        <SelectInstrument enseignement="1" anneeEnCour={user[usurpation].idAnneeScolaire} value={valueAffectation} />
                    </div>
                )}
                {open.event === 'addEnseignant' && (
                    <>
                        <h3 className=" textCenter">Recherche d'un enseignant</h3>
                        <SelectInstrument enseignement="1" anneeEnCour={user[usurpation].idAnneeScolaire} value={valueAffectation} />
                        <Table
                            columns={columnsRecherche}
                            dataSource={dataRechercheEnseignant}
                            pagination={{
                                showTotal: (total, range) => `${range[0]}-${range[1]} / ${total} `,
                                position: ['topRight'],
                                total: dataRechercheEnseignant?.length,
                                defaultCurrent: 1,
                                defaultPageSize: 10,
                            }}
                        />
                    </>
                )}
            </Modal>
            <div className="flex">
                <h3 className="w100 nomargin">Enseignants</h3>
                {testDroit(user[usurpation].roleFonction, fonctionAtelier.Associer_enseignant) && !window.matchMedia('(max-width: 576px)').matches && (
                    <PlusSquareOutlined
                        className="cVert fontSize20"
                        onClick={() => {
                            recupDataRechercheEnseignant();
                            setOpen({ open: true, event: 'addEnseignant', width: '60%' });
                        }}
                        disabled={+id === 0}
                    />
                )}
            </div>
            <hr></hr>
            {dataEnseignant.length !== 0 && <Table columns={columns} dataSource={dataEnseignant} pagination={false} />}
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        notification(note) {
            dispatch({ type: 'notification', note });
        },
    };
}

export default connect(null, mapDispatchToProps)(AtelierEnseignants);
