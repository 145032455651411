/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import local from 'antd/es/date-picker/locale/fr_FR';

import { Button, Select, Input, DatePicker } from 'antd';

import { GET, POST } from '../../components/Utils/requete';
import { StatutFrais } from '../../components/Utils/consts';
import JourFrais from '../../components/organism/Frais/jourFrais';
import euroFormate from '../../components/Utils/euroFormate';
import dateSave from '../../components/Utils/dateSave';
import DateHeureFormat from '../../components/Utils/DateHeureFormat';
import { testDroit } from '../../components/Utils/testDroit';

import * as fonctionFrais from '../../fonctionsRoles/frais';
import formatSelect from '../../components/Utils/FormatSelect';

const Frais = (props) => {
    const history = useHistory();
    const { id } = useParams();
    const { type } = useParams();
    const [elemJour, setElemJour] = useState([]);
    const [delT, setDelT] = useState(false);
    const infoJour = useSelector((state) => state.FraisJour);
    const refreshJour = useSelector((state) => state.FraisJour.refreshJour);
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    const [fraisReculPeriode, setFraisReculPeriode] = useState(0);
    const [statusFrais, setStatutFrais] = useState(1);
    const [anneesScolaire, setAnneesScolaire] = useState([]);
    const [decalageAnnee, setDecalageAnnee] = useState(0);
    const [infoFrais, setInfoFrais] = useState({
        idFrais: undefined,
        dateDemande: undefined,
        periode: '',
        nonbreDeJour: 0,
        kilometrage: 0,
        montantDivers: 0,
        nombreDeRepas: 0,
        brouillon: false,
        fk_fraisStatut: 1,
        gestion: type === 'gestion' ? true : false,
        vehicule: [
            {
                vehiculeMarque: '',
                vehiculeModele: '',
                vehiculePuissance: undefined,
                vehiculeImmatriculation: '',
                vehiculeCarburant: '',
            },
        ],
        fk_user: undefined,
        nomPrenomUtilisateur: undefined,
        fk_anneeScolaire: undefined,
        fraisJournees: [],
        fk_commune: undefined,
    });
    async function controlFraisPeriode(date) {
        if (+id === 0) {
            const data = {
                idAgent: user[usurpation].fk_id,
                anneeScolaire: infoFrais.fk_anneeScolaire,
                listStatuts: [1, 2, 3, 4],
            };
            const response = await POST('/api/utilisateursAdministratifs/listFrais/', data);
            for (let index = 0; index < response.data.length; index++) {
                if (dayjs(response.data[index].periode).format('MM/YYYY') === dayjs(date).format('MM/YYYY'))
                    return props.notification({ message: 'Vous avez déjà une note de frais créé pour cette periode' });
            }
        }
        if (Object.keys(infoJour).includes('jour 1'))
            return props.notification({ message: 'Vous devez supprimer les journées si vous voulez changer de periode' });
        // props.fraisPeriode(date);
        setInfoFrais((prevState) => ({
            ...prevState,
            periode: date,
        }));
    }
    async function recupCommune(annee) {
        if (Object.keys(infoJour).includes('jour 1'))
            return props.notification({ message: " Vous devez supprimer les journées si vous voulez changer d'année scolaire" });
        const response = await GET(`/api/utilisateursAdministratifs/communeService?idUtilisateur=${user[usurpation].fk_id}&fk_anneeScolaire=${annee}`);
        if (!response.success) return props.notification({ message: 'Vous devez avoir une fiche services pour créer des notes de frais' });
        setInfoFrais((prevState) => ({
            ...prevState,
            fk_commune: response.data.fk_commune,
        }));
        setDecalageAnnee(anneesScolaire.findIndex((e) => e.value === annee));
        setInfoFrais((prevState) => ({
            ...prevState,
            fk_anneeScolaire: annee,
        }));
    }
    async function dataAnneeScolaire() {
        const response = await GET('/api/anneesScolaire/list');

        if (+id === 0) response.data.splice(2, response.data.length - 2);
        setAnneesScolaire(formatSelect(response.data, 'idAnneeScolaire', 'libelleAnneeScolaire'));
    }
    async function vehicule() {
        const response = await GET(`/api/utilisateursAdministratifs/vehicule?idUtilisateur=${user[usurpation].fk_id}`);
        if (response.success)
            setInfoFrais((prevState) => ({
                ...prevState,
                nomPrenomUtilisateur: user[usurpation].nomPrenomUtilisateur,
                vehicule: response.data,
            }));
    }
    function createJour(dataJ) {
        if (infoFrais.periode || dataJ) {
            let elemDiv = [];
            let isEven;
            if (dataJ) {
                for (let elem = 0; elem < dataJ.length; elem++) {
                    if (elem % 2 === 0) {
                        isEven = {};
                    } else {
                        isEven = { backgroundColor: '#dadada' };
                    }
                    const data = (
                        <div className="ml2 mr2 border1darkgrey" style={isEven}>
                            <div className="flex ml1 mb1">
                                <h3 className="mb0 w100">Journée : {elem + 1}</h3>
                            </div>
                            <JourFrais
                                date={infoFrais.periode}
                                fk_commune={infoFrais.fk_commune}
                                jour={elem + 1}
                                dataJ={dataJ[elem]}
                                brouillon={infoFrais.brouillon}
                            />
                            .
                        </div>
                    );
                    elemDiv.push(data);
                    setElemJour(...elemJour, elemDiv);
                }
            } else {
                if (elemJour.length % 2 !== 0) {
                    isEven = { backgroundColor: '#dadada' };
                }
                elemDiv = (
                    <div className="ml2 mr2 border1darkgrey" style={isEven}>
                        <div className="flex ml1 mb1">
                            <h3 className="mb0 w100">Journée : {elemJour.length + 1}</h3>
                        </div>
                        <JourFrais date={infoFrais.periode} fk_commune={infoFrais.fk_commune} jour={elemJour.length + 1} />
                    </div>
                );
                setElemJour([...elemJour, elemDiv]);
            }
        } else {
            props.notification({ message: 'Veuillez sélectionner une periode' });
        }
    }
    function delJour(index) {
        props.removeJour(elemJour.length);
        const tableau = elemJour;
        tableau.splice(index - 1, 1);
        setElemJour(tableau);
        setDelT(!delT);
    }
    function calculFrais() {
        const noMatch = ['periode', 'refreshJour'];
        let jourKeys = Object.keys(infoJour).filter((e) => !noMatch.includes(e));
        if (jourKeys.indexOf('refreshJour') !== -1) {
            jourKeys.splice(jourKeys.indexOf('refreshJour'), 1);
        }
        let repasNb = 0;
        let kilometrage = 0;
        let diverMB = 0;
        for (let elem = 0; elem < jourKeys.length; elem++) {
            repasNb = repasNb + +infoJour[jourKeys[elem]].NBR;
            kilometrage = kilometrage + +infoJour[jourKeys[elem]].kilometrage;
            diverMB = diverMB + +infoJour[jourKeys[elem]].MDivers;
        }
        setInfoFrais((prevState) => ({
            ...prevState,
            nombreDeRepas: repasNb,
            kilometrage: kilometrage,
            montantDivers: euroFormate(diverMB),
            nonbreDeJour: Object.keys(jourKeys).length - 1 === -1 ? 0 : Object.keys(jourKeys).length - 1,
        }));
    }
    function ButtonSave() {
        let tableauBouton = testDroit(user[usurpation].roleFonction, fonctionFrais.Modification_statut)
            ? [
                  {
                      label: 'A compléter',
                      css: 'ml30px cVert borderCvert xs_w100 xs_nomargin xs_mb2',
                      statut: 1,
                  },
                  {
                      label: 'Instruction en cours',
                      css: 'ml30px cVert borderCvert xs_w100 xs_nomargin xs_mb2',
                      statut: 3,
                  },
                  { label: 'Acceptée', css: 'cbleu border1bleu ml30px xs_w100 xs_nomargin xs_mb2', statut: 4 },
                  { label: 'Refusée', css: 'ml30px xs_w100 xs_nomargin xs_mb2', statut: 5 },
              ]
            : [];
        if (!type) {
            tableauBouton = [
                {
                    label: 'Mémoriser',
                    css: 'ml30px cVert borderCvert xs_w100 xs_nomargin xs_mb2',
                    statut: 1,
                },
                { label: 'Soumettre', css: 'cbleu border1bleu ml30px xs_w100 xs_nomargin xs_mb2', statut: 2 },
            ];
        }
        const affichageBouton = () => {
            const bouton = [];
            for (let index = 0; index < tableauBouton.length; index++) {
                bouton.push(
                    <Button
                        danger={tableauBouton[index].label === 'Refusée'}
                        disabled={infoFrais.brouillon}
                        className={tableauBouton[index].css}
                        onClick={() => {
                            saveFrais(tableauBouton[index].statut);
                        }}
                    >
                        {tableauBouton[index].label}
                    </Button>,
                );
            }
            return bouton;
        };
        return affichageBouton();
    }
    async function recupDataFrais() {
        const response = await GET(`/api/frais/${id}`);
        if (response.success) {
            // props.fraisPeriode(response.data.periode);
            createJour(response.data.fraisJournees);
            let km = 0;
            let repas = 0;
            let montant = 0;
            for (let elem = 0; elem < response.data.fraisJournees.length; elem++) {
                km = km + response.data.fraisJournees[elem].kilometrage;
                repas = repas + response.data.fraisJournees[elem].nombreRepas;
                montant = montant + response.data.fraisJournees[elem].montantDivers;
            }
            response.data.periode = dayjs(response.data.periode);
            setInfoFrais(
                Object.assign(
                    {
                        nonbreDeJour: response.data.fraisJournees.length,
                        kilometrage: km,
                        montantDivers: euroFormate(montant),
                        nombreDeRepas: repas,
                        brouillon: +response.data.fk_fraisStatut !== 1 && !type ? true : false,
                        gestion: +response.data.fk_fraisStatut !== 1 && !type ? true : false,
                    },
                    response.data,
                ),
            );
        }
    }
    async function paiementOuverte() {
        const response = await GET('/api/parametres/list?categorie=general', undefined, { redirectOnError: false });
        let index = response.data.findIndex((e) => e.cle === 'fraisReculPeriode');

        if (index > -1) {
            setFraisReculPeriode(response.data[index].valeur);
        }
    }
    async function saveFrais(statut) {
        const jour = Object.values(infoJour);
        const tableauJour = jour.filter((elem) => elem !== false);
        const fraisJournees = function () {
            const data = [];
            for (let elem = 0; elem < tableauJour.length; elem++) {
                const tableauTrajet = Object.values(tableauJour[elem]).filter((i) => {
                    return typeof i === 'object';
                });
                if (tableauTrajet.length !== 0) {
                    const dataTrajet = [];
                    for (let elem = 3; elem < tableauTrajet.length; elem++) {
                        const fraisTrajets = {
                            idFraisTrajet: null,
                            fk_fraisJournee: null,
                            ordre: elem + 1,
                            fk_siteA: tableauTrajet[elem].trajetD,
                            fk_siteB: tableauTrajet[elem].trajetV,
                            kilometrage: tableauTrajet[elem].km ? tableauTrajet[elem].km : 0,
                            temps: '00:00:00',
                        };
                        dataTrajet.push(fraisTrajets);
                    }
                    const dataJour = {
                        idFraisJournee: null,
                        fk_frais: null,
                        dateJour: DateHeureFormat({ BDD: 'date', date: tableauJour[elem].date }),
                        heureDepart: DateHeureFormat({ BDD: 'heure', date: tableauJour[elem].heureDepart }),
                        heureRetour: DateHeureFormat({ BDD: 'heure', date: tableauJour[elem].heureRetour }),
                        fk_fraisType: tableauJour[elem].typeJour,
                        motif: tableauJour[elem].motif,
                        nombreRepas: tableauJour[elem].NBR,
                        montantDivers: tableauJour[elem].MDivers,
                        kilometrage: tableauJour[elem].kilometrage,
                        fraisTrajets: dataTrajet,
                    };
                    data.push(dataJour);
                }
            }
            return data;
        };
        const data = {
            idFrais: id === 0 ? null : id,
            fk_anneeScolaire: infoFrais.fk_anneeScolaire,
            fk_fraisStatut: statut,
            dateDemande: dateSave(),
            fk_user: !infoFrais.fk_user ? user[usurpation].fk_id : infoFrais.fk_user,
            periode: dayjs(infoFrais.periode).format('YYYY-MM-DD'),
            fraisJournees: fraisJournees(),
        };
        let testData = true;
        if (data.periode === '') {
            testData = false;
        }
        for (let elem = 0; elem < data.fraisJournees.length; elem++) {
            if (
                data.fraisJournees[elem].dateJour === '' ||
                data.fraisJournees[elem].heureDepart === '' ||
                data.fraisJournees[elem].heureRetour === '' ||
                data.fraisJournees[elem].fk_fraisType === ''
            ) {
                testData = false;
            }
        }
        if (testData === true) {
            const response = await POST('/api/frais/0 ', data, {
                redirectOnError: false,
            });
            if (response.success === true) {
                if (response.data.fk_fraisStatut === 3) {
                    history.push(`/liste-gestion-frais`);
                } else {
                    history.push(`/fiche-frais/${response.data.idFrais}${type === 'gestion' ? '/gestion' : ''}`);
                }
                setStatutFrais(response.data.fk_fraisStatut);
                const notificationStatus = [
                    "Cette note de frais est enregistrée comme brouillon. Elle peut être complétée avant d'être soumise pour validation.",
                    'Cette note de frais a été soumise à validation',
                    'Cette note de frais est en cour de validation',
                    'Cette note de frais a été  validée',
                    'Cette note de frais a été refusée',
                ];
                props.notification({
                    message: notificationStatus[statut - 1],
                    status: true,
                });
            }
        } else {
            props.notification({ message: 'Tous les champs date, heure et type doivent être remplis' });
        }
    }
    useEffect(() => {
        dataAnneeScolaire();
        props.removeAll();
        if (id !== '0') {
            recupDataFrais();
        } else {
            vehicule();
        }
    }, []);
    useEffect(() => {
        paiementOuverte();
        calculFrais();
    }, [delT, refreshJour]);
    return (
        <div>
            <div>
                <div className="ml2 mr2 ">
                    <div className="flex mt1">
                        <h3 className="mb0 w75">Edition d'une demande de frais : {infoFrais.nomPrenomUtilisateur}</h3>
                        <h3 className="mb0 w25 ">{`${infoFrais.vehicule[0].vehiculeMarque} ${infoFrais.vehicule[0].vehiculeModele} ${infoFrais.vehicule[0].vehiculePuissance}CV`}</h3>
                    </div>
                    <hr></hr>
                </div>
                <div className="flex xs_directionColumn">
                    <div className="w50 xs_w90 xs_ml2">
                        <div className="textRight alignICenter flex mb10px xs_block xs_textLeft">
                            <label className="w35">Statut</label>
                            <Input className="nomargin ml30px w50" disabled={true} value={StatutFrais.filter((e) => e.id === statusFrais)[0].lib} />
                        </div>
                        <div className=" alignICenter flex mb10px xs_block xs_textLeft">
                            <label className="textRight w35 xs_inlineBlock">Année Scolaire</label>
                            <Select
                                disabled={+id !== 0}
                                className="nomargin ml30px w50"
                                value={infoFrais.fk_anneeScolaire}
                                onChange={(values) => recupCommune(values)}
                                options={anneesScolaire}
                            />
                        </div>
                        <div className="textRight alignICenter flex mb10px xs_block xs_textLeft">
                            <label className="w35 xs_inlineBlock">Periode</label>
                            <DatePicker
                                locale={local}
                                disabled={infoFrais.gestion || !infoFrais.fk_anneeScolaire}
                                value={infoFrais.periode}
                                className="nomargin ml30px w50"
                                onChange={(date) => controlFraisPeriode(date)}
                                style={{ textAlign: 'left' }}
                                picker="month"
                                maxDate={decalageAnnee === 0 ? dayjs().subtract(decalageAnnee, 'years') : dayjs().month(7).subtract(decalageAnnee, 'years')}
                                minDate={
                                    decalageAnnee === 0
                                        ? dayjs().subtract(decalageAnnee, 'years').subtract(+fraisReculPeriode, 'months')
                                        : dayjs().month(7).subtract(decalageAnnee, 'years').subtract(3, 'months')
                                }
                                format={'MM-YYYY'}
                            />
                        </div>
                    </div>
                    <div className="w50 xs_w90 xs_ml2">
                        <div className="textRight alignICenter flex mb1">
                            <label className="w25">Nombre de journée</label>
                            <Input
                                className="nomargin ml30px w50 "
                                disabled={true}
                                value={infoFrais.nonbreDeJour}
                                onChange={(event) =>
                                    setInfoFrais((prevState) => ({
                                        ...prevState,
                                        nonbreDeJour: event.target.value,
                                    }))
                                }
                            />
                        </div>
                        <div className="textRight alignICenter flex mb1">
                            <label className="w25">Kilométrage</label>
                            <Input
                                className="nomargin ml30px w50"
                                disabled={true}
                                value={infoFrais.kilometrage}
                                onChange={(event) =>
                                    setInfoFrais((prevState) => ({
                                        ...prevState,
                                        kilometrage: event.target.value,
                                    }))
                                }
                            />
                        </div>
                        <div className="textRight alignICenter flex mb1">
                            <label className="w25">Nombre de repas</label>
                            <Input
                                className="nomargin ml30px w50"
                                disabled={true}
                                value={infoFrais.nombreDeRepas}
                                onChange={(event) =>
                                    setInfoFrais((prevState) => ({
                                        ...prevState,
                                        nombreDeRepas: event.target.value,
                                    }))
                                }
                            />
                        </div>
                        <div className="textRight alignICenter flex mb1">
                            <label className="w25">Montant Divers</label>
                            <Input
                                className="nomargin ml30px w50"
                                disabled={true}
                                value={infoFrais.montantDivers}
                                onChange={(event) =>
                                    setInfoFrais((prevState) => ({
                                        ...prevState,
                                        montantDivers: event.target.value,
                                    }))
                                }
                            />
                        </div>
                    </div>
                </div>
                {type ? <ButtonSave /> : <></>}
            </div>
            <br />
            <div>{elemJour}</div>
            <Button disabled={infoFrais.gestion} className="ml30px cVert borderCvert xs_w100 xs_nomargin xs_mb2 xs_mt5" onClick={() => createJour()}>
                Ajouter une journée
            </Button>
            <Button
                disabled={infoFrais.gestion}
                danger
                className=" m2 xs_w100 xs_nomargin xs_mb2"
                onClick={() => {
                    delJour(elemJour.length);
                }}
            >
                Supprimer cette journée
            </Button>
            <div className="xs_flex xs_directionColumn">
                <ButtonSave />
            </div>
        </div>
    );
};
function mapDispatchToProps(dispatch) {
    return {
        filtre(filtre) {
            dispatch({ type: 'simple', filtre });
        },
        removeJour(nb) {
            dispatch({ type: 'removeJour', nb });
        },
        removeAll() {
            dispatch({ type: 'removeAll' });
        },
        fraisPeriode(periode) {
            dispatch({ type: 'periode', periode });
        },
        notification(note) {
            dispatch({ type: 'notification', note });
        },
    };
}
export default connect(null, mapDispatchToProps)(Frais);
