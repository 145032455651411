/* eslint-disable react-hooks/exhaustive-deps */
/**
 *
 * @author Vijaya Srikar PORALLA
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link, useParams, useLocation } from 'react-router-dom';

import { Paper, Tabs, Tab, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';

import PaginationTable from '../../components/organism/Table/Pagination';
import { GET, POST } from '../../components/Utils/requete';
import TabPanel from '../../components/organism/Onglet/TabPanel';
import TableHeadCustom from '../../components/organism/Table/TableHead';
import setData from '../../components/organism/Table/setDataTableau';
import SelectSimple from '../../components/organism/SelectSimple/SelectSimple';
import SelectMultiple from '../../components/organism/SelectMultiple/SelectMultiple';
import creationTableauFiltre from '../../components/Utils/createTableauFiltre';
import { calculDuree, minutesToDuration } from '../../components/Utils/calculTemp';
import TempCoursFormat from '../../components/organism/MiseEnFormeTempsCours/TempCoursFormat';
import Contact from '../../components/organism/Contact/Contact';
import ExportExcel from '../../components/organism/ExportTableau/exportExcel';
import LienLivret from '../../components/organism/LienLivret/lienLivret';
import CalendarCustom from '../../components/organism/Calendar/Calendar';
import InfoAgent from '../../components/organism/InfoAgent/infoAgent';
import ButtonCustom from '../../components/atoms/ButtonCustom';
import { testDroit } from '../../components/Utils/testDroit';

import useStylesMaterielUIGlobal from '../../styles/StyleMaterielUIGlobal';

import { tableHeadEleve, tableHeadAtelier, tableHeadAtelierExport, tableHeadEleveExport, badge, individuelFiltre } from './consts';

import * as fonctionMesCours from '../../fonctionsRoles/mesCours';

const MesCours = (props) => {
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    const historyLoc = useLocation();
    let { id } = useParams();
    const css = useStylesMaterielUIGlobal();
    const selectMultiple = useSelector((state) => state.SelectMultiple);
    const selectSimple = useSelector((state) => state.SelectSimple);
    const refreshMultiple = useSelector((state) => state.SelectMultiple.refreshMultiple);
    const refreshSimple = useSelector((state) => state.SelectSimple.refreshSimple);
    const [selected, setSelected] = useState([]);
    const isSelected = (name) => selected.indexOf(name) !== -1;
    const [value, setValue] = useState(0);
    const [tableauFiltre, setTableauFiltre] = useState({
        anneeScolaire: [],
        site: [],
        salle: [],
    });
    const [dataEleve, setDataEleve] = useState([]);
    const [dataAtelier, setDataAtelier] = useState([]);
    const [dureesSitesEleves, setDureesSitesEleves] = useState([]);
    const [dureeSitesAteliers, setDureesSitesAteliers] = useState([]);
    const [mailSelect, setMailS] = useState([]);
    const [mail, setMail] = useState([]);
    const handleClick = (name, email) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
        let selectMail = mailSelect;
        for (let index = 0; index < email.split('|-|').length; index++) {
            selectMail.push(email.split('|-|')[index].split('|')[2]);
        }
        setMailS(selectMail);
    };
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function updatefiltre(keys, valueI) {
        setTableauFiltre((prevState) => ({
            ...prevState,
            [keys]: valueI,
        }));
    }

    function FiltreOnglet(props) {
        let exportData = dataEleve;
        let exportHead = tableHeadEleveExport;
        if (props.atelier) {
            exportHead = tableHeadAtelierExport;
            exportData = dataAtelier;
        }
        return (
            <div className="flex  alignICenter xs_directionColumn xs_mt5 xs_mb5">
                <SelectSimple
                    tableau={tableauFiltre.anneeScolaire}
                    inputLabel={'Année Scolaire'}
                    indice={'AnneeScolaire'}
                    classe={[css.w25, css.mr2, css.xs_w80]}
                ></SelectSimple>
                <>
                    <SelectSimple
                        tableau={individuelFiltre}
                        indice={'AtelierIndividuel'}
                        inputLabel={'Type atelier'}
                        classe={[css.w25, css.mr2, css.xs_w80]}
                    ></SelectSimple>
                    <SelectMultiple tableau={tableauFiltre.site} inputLabel={'Site'} indice={'Site'} classe={[css.w25, css.mr2, css.xs_w80]}></SelectMultiple>
                    {!props.eleve ? (
                        <SelectMultiple
                            tableau={tableauFiltre.salle}
                            inputLabel={'Salle'}
                            indice={'Salles'}
                            classe={[css.w25, css.mr2, css.xs_w80]}
                        ></SelectMultiple>
                    ) : null}

                    {!props.calendrier ? (
                        (testDroit(user[usurpation].roleFonction, fonctionMesCours.Envoi_email) && +id !== user[usurpation].fk_id) ||
                        testDroit(user[usurpation].roleFonction, fonctionMesCours.MonCompte_Envoi_email) ? (
                            <ExportExcel data={exportData} head={exportHead} nomFichier="Liste" />
                        ) : null
                    ) : null}
                </>
            </div>
        );
    }

    function Badge(props) {
        if (props.lib) {
            return (
                <>
                    <span className={badge[props.type].style}>
                        {badge[props.type].label} {props.lib}
                    </span>
                </>
            );
        } else {
            return <></>;
        }
    }

    function DureeSite(props) {
        return (
            <tr>
                <td>{props.site.libelleSite}</td>
                <td className="textRight">{props.site.nombre}</td>
                <td className="textRight">{minutesToDuration(props.site.duree)}</td>
                {!value ? <td className="textRight">{minutesToDuration(props.site.dureeAtelier)}</td> : null}
                <br />
            </tr>
        );
    }

    function DureesSites(props) {
        return (
            <div>
                <legend>Service réel</legend>
                <table className="w50">
                    <thead>
                        <tr>
                            <td className="w40">Site</td>
                            <td className="textRight w15">Nombre</td>
                            <td className="textRight w20">Durée effective</td>
                            {!value ? <td className="textRight w20">Durée théorique</td> : null}
                        </tr>
                    </thead>
                    <tbody>
                        {props.sites.map((site) => (
                            <DureeSite site={site} />
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
    function ElevesDansAtelier(props) {
        return (
            <>
                {(testDroit(user[usurpation].roleFonction, fonctionMesCours.Lien_profil_eleve) && +id !== user[usurpation].fk_id) ||
                testDroit(user[usurpation].roleFonction, fonctionMesCours.MonCompte_lien_profil_eleve) ? (
                    <Link to={`/fiche-eleve/${props.eleve.idEleve}`}>{props.eleve.nomPrenomEleve}</Link>
                ) : (
                    props.eleve.nomPrenomEleve
                )}
                <br />
                {props.eleve.concatInfoResponsable.split('|-|').map((e) => {
                    return (
                        <Contact
                            email={e.split('|')[2]}
                            idresponsable={[e.split('|')[0]]}
                            mobile={e.split('|')[3]}
                            telephone={e.split('|')[4]}
                            nomPrenom={e.split('|')[1]}
                        />
                    );
                })}
            </>
        );
    }

    function linkAtelier() {
        return props.savePathAtelier(historyLoc.pathname);
    }

    function AteliersDansEleve(props) {
        return (
            <>
                {(testDroit(user[usurpation].roleFonction, fonctionMesCours.Lien_livret_eleve) && +id !== user[usurpation].fk_id) ||
                testDroit(user[usurpation].roleFonction, fonctionMesCours.MonCompte_lien_livret_de_eleve) ? (
                    <LienLivret data={props.atelier} className={[css.p0, css.mr2]} />
                ) : null}

                {(testDroit(user[usurpation].roleFonction, fonctionMesCours.Lien_atelier) && +id !== user[usurpation].fk_id) ||
                testDroit(user[usurpation].roleFonction, fonctionMesCours.MonCompte_vers_atelier) ? (
                    <Link
                        to={`/fiche-atelier/${props.atelier.idAtelier}`}
                        onClick={() => {
                            linkAtelier();
                        }}
                    >
                        Atelier
                    </Link>
                ) : (
                    'Atelier'
                )}
                <span> Site : </span>
                {props.atelier.libelleSite}
                <span> Instrument : </span>
                {props.atelier.libelleInstrument}
                {!props.atelier.libelleCycle ? null : <span> Niveau FM-G : </span>}
                <Badge lib={props.atelier.libelleCycle} type={2} />
                <Badge lib={props.atelier.libelleAnnee} type={1} />
                <div></div>
                <span> Durée : {props.atelier.dureeAtelier} </span>

                <br />
            </>
        );
    }

    function extractionDureesSitesAteliers(eleves) {
        const sites = [];
        let totalDuree = 0;
        let totalNombre = 0;
        let siteIndex;
        let site;
        let duree;
        eleves.forEach((eleve) => {
            duree = calculDuree(eleve.heureDebut, eleve.heureFin);
            totalDuree += duree;
            totalNombre++;
            siteIndex = sites.findIndex((site) => site.fk_site === eleve.fk_site);
            if (siteIndex < 0) {
                site = {
                    fk_site: eleve.fk_site,
                    libelleSite: eleve.libelleSite,
                    duree: 0,
                    nombre: 0,
                    ateliers: [],
                };
                siteIndex = sites.length;
                sites.push(site);
            }
            if (siteIndex >= 0) {
                site = sites[siteIndex];
                if (site.ateliers.findIndex((atelier) => atelier === eleve.idAtelier) < 0) {
                    site.ateliers.push(eleve.idAtelier);
                    site.duree += duree;
                    site.nombre++;
                }
            }
        });
        sites.push({
            fk_site: -1,
            libelleSite: 'Total',
            duree: totalDuree,
            nombre: totalNombre,
            ateliers: [],
        });

        return sites;
    }

    function extractionDureesSitesEleves(eleves) {
        const sites = [];
        let duree = 0;
        let totalDuree = 0;
        let totalNombre = 0;
        let ateliers;
        let siteIndex;
        let site;
        let dureeAtelier;
        let totalDureeAtelier = 0;
        const dejaComptabilise = [];
        eleves.forEach((eleve) => {
            ateliers = eleve.ateliers;
            ateliers.forEach((a) => {
                if (!dejaComptabilise.includes(a.idAtelier)) {
                    duree = calculDuree(a.heureDebut === null ? '00:00:00' : a.heureDebut, a.heureFin === null ? '00:00:00 ' : a.heureFin);
                    dureeAtelier = a.dureeAtelier;
                    totalDuree += duree;
                    totalDureeAtelier += dureeAtelier;
                    totalNombre++;
                    siteIndex = sites.findIndex((site) => site.fk_site === a.fk_site);
                    if (siteIndex < 0) {
                        site = {
                            fk_site: a.fk_site,
                            libelleSite: a.libelleSite,
                            duree: 0,
                            dureeAtelier: 0,
                            nombre: 0,
                            ateliers: [],
                        };
                        siteIndex = sites.length;
                        sites.push(site);
                    }
                    if (siteIndex >= 0) {
                        site = sites[siteIndex];
                        if (site.ateliers.findIndex((atelier) => atelier === a.idAtelier) < 0) {
                            site.ateliers.push(a.idAtelier);
                            site.duree += duree;
                            site.dureeAtelier += dureeAtelier;
                            site.nombre++;
                        }
                    }
                    dejaComptabilise.push(a.idAtelier);
                }
            });
        });

        sites.push({
            fk_site: -1,
            libelleSite: 'Total',
            duree: totalDuree,
            dureeAtelier: totalDureeAtelier,
            nombre: totalNombre,
            ateliers: [],
        });
        return sites;
    }
    function positionTopBarre() {
        if (testDroit(user[usurpation].roleFonction, fonctionMesCours.Lien_services) || testDroit(user[usurpation].roleFonction, fonctionMesCours.Lien_profil))
            return '111px';
        return '63px';
    }
    async function recupUtilisateur() {
        const response = await GET('/api/utilisateursAdministratifs/' + id, undefined, { redirectOnError: false });
        if (response && response.success) {
            props.addNomPage('Cours & ateliers - ' + response.data.nomPrenomUtilisateur);
        }
    }

    async function recupElevesAttached() {
        const data = {
            fk_enseignant: id,
            fk_anneeScolaire: selectSimple.AnneeScolaire,
            fk_site: selectMultiple.Site,
            individuel: selectSimple.AtelierIndividuel === 2 || selectSimple.AtelierIndividuel === '' ? null : selectSimple.AtelierIndividuel,
        };
        const response = await POST('/api/utilisateursAdministratifs/listElevesAttached/', data, { redirectOnError: false });
        if (response.success) {
            setDureesSitesEleves(extractionDureesSitesEleves(response.data));
        }
        let tableauMail = [];
        for (let elem = 0; elem < response.data.length; elem++) {
            const contact = response.data[elem].concatInfoResponsable.split('|-|');
            for (let index = 0; index < contact.length; index++) {
                tableauMail.push(contact[index].split('|')[2]);
            }
        }
        setMail(tableauMail);
        setDataEleve(response.data);
    }
    async function recupAteliersAttached() {
        const data = {
            fk_enseignant: id,
            fk_anneeScolaire: selectSimple.AnneeScolaire,
            fk_site: selectMultiple.Site,
            fk_salle: selectMultiple.Salles,
            individuel: selectSimple.AtelierIndividuel === 2 || selectSimple.AtelierIndividuel === '' ? null : selectSimple.AtelierIndividuel,
        };
        const response = await POST('/api/utilisateursAdministratifs/listAteliersAttached/', data, { redirectOnError: false });
        if (response.success) {
            setDureesSitesAteliers(extractionDureesSitesAteliers(response.data));
        }
        setDataAtelier(response.data);
    }

    async function recupFiltre() {
        if (tableauFiltre.anneeScolaire.length === 0) {
            const anneeScolaireFiltre = await GET('/api/anneesScolaire/list');
            updatefiltre('anneeScolaire', anneeScolaireFiltre.data);
        }
        const siteFiltre = await GET('/api/sites/liste');
        updatefiltre('site', siteFiltre.data);
        const dataSalle = {
            fk_site: selectMultiple.Site,
        };
        const salleFiltre = await POST('/api/salles/liste', dataSalle);
        updatefiltre('salle', creationTableauFiltre(salleFiltre.data, 'idSalle', 'libelleSalle', 'libelleSite'));
    }

    useEffect(() => {
        props.filtre([{ AnneeScolaire: user.idAnneeScolaire }]);
        props.filtre([{ AtelierIndividuel: 2 }]);
    }, []);

    useEffect(() => {
        props.addNomPage('Cours & ateliers');
        recupFiltre();
        recupUtilisateur();
        recupAteliersAttached();
        recupElevesAttached();
    }, [refreshSimple, refreshMultiple, id]);

    return (
        <div>
            <InfoAgent idUtilisateur={id} idService={fonctionMesCours.Lien_services} idProfil={fonctionMesCours.Lien_profil} type="cours" />
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                style={
                    window.matchMedia('(max-width: 576px)').matches
                        ? {}
                        : { position: 'fixed', width: 'calc(100% - 210px)', zIndex: '2', top: positionTopBarre() }
                }
            >
                <Tab label="Élèves" />
                <Tab label="Cours & Ateliers" />
                <Tab label="Planning" />
            </Tabs>
            <TabPanel value={value} index={0} style={{ marginTop: '100px' }}>
                <FiltreOnglet eleve />
                <div className="flex spaceBetween alignICenter">
                    {value === 0 ? (
                        <div>
                            <ButtonCustom
                                variant="outlined"
                                size="small"
                                onClick={() => {
                                    setTimeout(() => {
                                        setSelected([]);
                                        setMailS([]);
                                    }, 1);
                                }}
                                className={[css.xs_w100, css.xs_mb2]}
                            >
                                <Contact email={mailSelect} lib="Email à la selection" />
                            </ButtonCustom>
                            <ButtonCustom variant="outlined" size="small" className={[css.xs_w100, css.xs_mb2]}>
                                <Contact email={mail} lib="Email à tous" />
                            </ButtonCustom>
                        </div>
                    ) : null}
                    <PaginationTable data={dataEleve} page={props.Page[0].page} row={props.Page[0].rowsPerPage}></PaginationTable>
                </div>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHeadCustom data={tableHeadEleve}></TableHeadCustom>
                        <TableBody>
                            {setData(props.recherche.fn, dataEleve, props.Page[0].page, props.Page[0].rowsPerPage, props.orderState, props.orderByState).map(
                                (row, index) => {
                                    return (
                                        <TableRow
                                            className={css.trbody}
                                            key={row.nomPrenomEleve}
                                            selected={isSelected(row.nomPrenomEleve)}
                                            onClick={() => handleClick(row.nomPrenomEleve, row.concatInfoResponsable)}
                                        >
                                            <TableCell className={css.td} component="th" scope="row">
                                                <div>Elève</div>
                                                {(testDroit(user[usurpation].roleFonction, fonctionMesCours.Lien_profil_eleve) &&
                                                    +id !== user[usurpation].fk_id) ||
                                                testDroit(user[usurpation].roleFonction, fonctionMesCours.MonCompte_lien_profil_eleve) ? (
                                                    <Link to={`/fiche-eleve/${row.idEleve}`}>{row.nomPrenomEleve}</Link>
                                                ) : (
                                                    row.nomPrenomEleve
                                                )}
                                                <Badge lib={row.libelleStatut} type={3} />
                                            </TableCell>
                                            <TableCell className={css.td} component="th" scope="row">
                                                {row.ateliers.map((atelier) => (
                                                    <AteliersDansEleve atelier={atelier} />
                                                ))}
                                            </TableCell>
                                            <TableCell className={css.td} component="th" scope="row">
                                                <div>Contact(s)</div>
                                                {row.concatInfoResponsable.split('|-|').map((e) => {
                                                    return (
                                                        <Contact
                                                            email={e.split('|')[2]}
                                                            idresponsable={[e.split('|')[0]]}
                                                            mobile={e.split('|')[3]}
                                                            telephone={e.split('|')[4]}
                                                            nomPrenom={e.split('|')[1]}
                                                            idFonction={fonctionMesCours.Lien_profil_responsable}
                                                        />
                                                    );
                                                })}
                                            </TableCell>
                                        </TableRow>
                                    );
                                },
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <PaginationTable data={dataEleve} page={props.Page[0].page} row={props.Page[0].rowsPerPage}></PaginationTable>
                <DureesSites sites={dureesSitesEleves} />
            </TabPanel>
            <TabPanel value={value} index={1} style={{ marginTop: '100px' }}>
                <FiltreOnglet atelier />
                <div className="flex spaceBetween alignICenter">
                    <PaginationTable data={dataAtelier} page={props.Page[0].page} row={props.Page[0].rowsPerPage}></PaginationTable>
                </div>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHeadCustom data={tableHeadAtelier}></TableHeadCustom>
                        <TableBody>
                            {setData(props.recherche.fn, dataAtelier, props.Page[0].page, props.Page[0].rowsPerPage, props.orderState, props.orderByState).map(
                                (row, index) => {
                                    return (
                                        <TableRow className={css.trbody} key={index}>
                                            <TableCell className={css.td} component="th" scope="row">
                                                <div>Type</div>
                                                {(testDroit(user[usurpation].roleFonction, fonctionMesCours.Lien_atelier) && +id !== user[usurpation].fk_id) ||
                                                testDroit(user[usurpation].roleFonction, fonctionMesCours.MonCompte_vers_atelier) ? (
                                                    <Link
                                                        to={`/fiche-atelier/${row.idAtelier}`}
                                                        onClick={() => {
                                                            props.savePathAtelier(historyLoc.pathname);
                                                        }}
                                                    >
                                                        {row.libelleType}
                                                    </Link>
                                                ) : (
                                                    row.libelleType
                                                )}
                                                <Badge lib={row.libelleGroupe} type={4} />
                                            </TableCell>
                                            <TableCell className={css.td} component="th" scope="row">
                                                <div>Site / Salle</div>
                                                {`${row.libelleSite}`}
                                                {row.libelleSalle ? ` / ${row.libelleSalle}` : null}
                                            </TableCell>
                                            <TableCell className={css.td} component="th" scope="row">
                                                <div>Horaires</div>
                                                <TempCoursFormat jour={row.jour} heureDebut={row.heureDebut} heureFin={row.heureFin} />
                                            </TableCell>
                                            <TableCell className={css.td} component="th" scope="row">
                                                <div>Elève(s)</div>
                                                {row.eleves.map((eleve) => (
                                                    <ElevesDansAtelier eleve={eleve} />
                                                ))}
                                            </TableCell>
                                        </TableRow>
                                    );
                                },
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <PaginationTable data={dataAtelier} page={props.Page[0].page} row={props.Page[0].rowsPerPage}></PaginationTable>
                <DureesSites sites={dureeSitesAteliers} />
            </TabPanel>
            <TabPanel value={value} index={2} style={{ marginTop: '100px' }}>
                <FiltreOnglet calendrier />
                <CalendarCustom tableau={dataAtelier} className="mt2" type="agent" />
            </TabPanel>
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        addNomPage(nomPage) {
            dispatch({ type: 'stock', nomPage });
        },
        filtre(filtre) {
            dispatch({ type: 'simple', filtre });
        },
        filtreMultiple(filtreMultiple) {
            dispatch({ type: 'multiple', filtreMultiple });
        },
        savePathAtelier(pathAtelier) {
            dispatch({ type: 'savePathAtelier', pathAtelier });
        },
    };
}
function mapStateToProps(state) {
    return {
        Page: state.Pagination,
        recherche: state.ReponseRecherche,
        orderState: state.Order,
        orderByState: state.OrderBy,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(MesCours);
