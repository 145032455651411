/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';

import crypto from 'crypto';

import { Paper, Card, CardActions, CardContent } from '@material-ui/core';

import ListeButton from '../../atoms/ButtonCustom';
import { GET } from '../../Utils/requete';
import euroFormate from '../../Utils/euroFormate';

import useStylesMaterielUIGlobal from '../../../styles/StyleMaterielUIGlobal';

const Paybox = () => {
    const user = useSelector((state) => state.Utilisateur);
    const css = useStylesMaterielUIGlobal();
    const usurpation = user.userC;
    const date = new Date();
    const [cotisation, setCotisation] = useState();
    const [dataPaybox, setDataPaybox] = useState();
    const [montant2, setMontant2] = useState();
    const [montant3, setMontant3] = useState();
    const [nonPayeur, setNonPayeur] = useState(false);
    const [affichagePaiement, setAffichagePaiement] = useState(false);
    const [paiementDate, setPaiementDate] = useState({
        cb1xFermeture: '',
        cbOuverture: '',
        cb2xFermeture: '',
        cb3xFermeture: '',
    });

    async function paybox(abonnement) {
        let URL;
        let secretKey;
        let email;
        if (process.env.REACT_APP_ENVIRONNEMENT === 'TEST') {
            URL = 'https://preprod-tpeweb.paybox.com/cgi/MYchoix_pagepaiement.cgi';
            secretKey = 'BAD2DDC869D4EE33C60AC60B77303889DE18DEADF41A4CC7F1B118D7AAE0A36CF4FF98A095077DC7E98E836242C775EE1758E936340F819796177DA3901D0E86';
            email = 'mich.tournay@gmail.com';
        } else {
            URL = 'https://tpeweb.paybox.com/cgi/MYchoix_pagepaiement.cgi';
            secretKey = dataPaybox.filter((e) => e.cle === 'secretKey')[0].valeur;
            email = user[usurpation].email;
        }

        let payment = {
            PBX_SITE: dataPaybox.filter((e) => e.cle === 'PBX_SITE')[0].valeur,
            PBX_RANG: dataPaybox.filter((e) => e.cle === 'PBX_RANG')[0].valeur,
            PBX_IDENTIFIANT: dataPaybox.filter((e) => e.cle === 'PBX_IDENTIFIANT')[0].valeur,
            PBX_DEVISE: '978',
            PBX_CMD: `${cotisation.idCotisation}|${user[usurpation].nomPrenomUtilisateur}|${user[usurpation].idUtilisateur}`,
            PBX_PORTEUR: email,
            PBX_RETOUR: 'montant:M;refcmd:R;auto:S;error:E;abonnement:B;',
            PBX_TIME: date.toISOString(),
            PBX_HASH: 'SHA512',
            PBX_EFFECTUE: `${process.env.REACT_APP_URL}/payment/success`,
            PBX_REFUSE: `${process.env.REACT_APP_URL}/payment/error`,
            PBX_ANNULE: `${process.env.REACT_APP_URL}/payment/cancelled`,
            PBX_ATTENTE: `${process.env.REACT_APP_URL}/payment/waiting`,
            PBX_REPONDRE_A: `${process.env.REACT_APP_URL}/api/reglements/cbPaybox`,
        };

        switch (abonnement) {
            case 2:
                payment.PBX_TOTAL = montant2.echeance1.prix;
                payment.PBX_2MONT1 = montant2.echeance2.prix;
                payment.PBX_DATE1 = montant2.echeance2.date;
                break;
            case 3:
                payment.PBX_TOTAL = montant3.echeance1.prix;
                payment.PBX_2MONT1 = montant3.echeance2.prix;
                payment.PBX_DATE1 = montant3.echeance2.date;
                payment.PBX_2MONT2 = montant3.echeance3.prix;
                payment.PBX_DATE2 = montant3.echeance3.date;
                break;
            default:
                payment.PBX_TOTAL = cotisation.montantTotal;
                break;
        }
        let params = [];
        for (let index = 0; index < Object.keys(payment).length; index++) {
            params.push(`${Object.keys(payment)[index]}=${Object.values(payment)[index]}`);
        }
        let message = params.join('&');
        const binaryKey = Buffer.from(secretKey, 'hex');
        payment.PBX_HMAC = crypto.createHmac(payment.PBX_HASH, binaryKey).update(message).digest('hex').toUpperCase();
        params = [];
        for (let index = 0; index < Object.keys(payment).length; index++) {
            params.push(`${Object.keys(payment)[index]}=${encodeURIComponent(Object.values(payment)[index])}`);
        }
        message = params.join('&');
        return (window.location = URL + '?' + message);
    }

    async function paiementOuverte() {
        const response = await GET('/api/parametres/list?categorie=reglement', undefined, { redirectOnError: false });
        const index = ['cb1xFermeture', 'cbOuverture', 'cb2xFermeture', 'cb3xFermeture'];
        if (response) {
            for (let elem = 0; elem < index.length; elem++) {
                setPaiementDate((prevState) => ({
                    ...prevState,
                    [index[elem]]: response.data[response.data.findIndex((e) => e.cle === index[elem])].valeur,
                }));
            }
        }
        const responsePaybox = await GET('/api/parametres/list?categorie=paybox', undefined, { redirectOnError: false });
        if (responsePaybox) {
            setDataPaybox(responsePaybox.data);
        }
    }
    function Boutton() {
        if (!cotisation || !montant2 || !montant3) {
            return <></>;
        }
        const bouttonPaiement = [
            <div className="w30">
                <Card>
                    <CardContent className="bgcVertOpacite0_7 textCenter">
                        <h4 className="mb0 mt2">Payer en 1 fois</h4>
                        <span className="fontSize0_7em">
                            {`Disponible jusqu'au 
                                ${new Date(paiementDate.cb1xFermeture).toLocaleDateString('fr-FR')}`}
                        </span>
                    </CardContent>
                    <CardContent>
                        <span>{date.toLocaleDateString('fr-FR')}</span>
                        <span className="floatRight mr5">{euroFormate(cotisation.montantTotal / 100)}</span>
                    </CardContent>
                    <CardActions className={css.center}>
                        <ListeButton variant="outlined" size="small" onClick={() => paybox()} color="primary">
                            Paiement
                        </ListeButton>
                    </CardActions>
                </Card>
            </div>,
        ];
        if (date.getTime() < new Date(paiementDate.cb2xFermeture).getTime()) {
            bouttonPaiement.push(
                <div className="w30">
                    <Card>
                        <CardContent className="bgcVertOpacite0_7 textCenter">
                            <h4 className="mb0 mt2">Payer en 2 fois</h4>
                            <span className="fontSize0_7em">
                                {`Disponible jusqu'au 
                                ${new Date(paiementDate.cb2xFermeture).toLocaleDateString('fr-FR')}`}
                            </span>
                        </CardContent>
                        <CardContent>
                            <div>
                                <span>{montant2.echeance1.date}</span>
                                <span className=" floatRight mr5">{euroFormate(montant2.echeance1.prix / 100)}</span>
                            </div>
                            <div>
                                <span>{montant2.echeance2.date}</span>
                                <span className=" floatRight mr5">{euroFormate(montant2.echeance2.prix / 100)}</span>
                            </div>
                        </CardContent>
                        <CardActions className={css.center}>
                            <ListeButton variant="outlined" size="small" onClick={() => paybox(2)} color="primary">
                                Paiement
                            </ListeButton>
                        </CardActions>
                    </Card>
                </div>,
            );
        }
        if (date.getTime() < new Date(paiementDate.cb3xFermeture).getTime()) {
            bouttonPaiement.push(
                <div className="w30">
                    <Card>
                        <CardContent className="bgcVertOpacite0_7 textCenter">
                            <h4 className="mb0 mt2">Payer en 3 fois</h4>
                            <span className="fontSize0_7em">
                                {`Disponible jusqu'au 
                                ${new Date(paiementDate.cb3xFermeture).toLocaleDateString('fr-FR')}`}
                            </span>
                        </CardContent>
                        <CardContent>
                            <div>
                                <span>{montant3.echeance1.date}</span>
                                <span className=" floatRight mr5">{euroFormate(montant3.echeance1.prix / 100)}</span>
                            </div>
                            <div>
                                <span>{montant3.echeance2.date}</span>
                                <span className=" floatRight mr5">{euroFormate(montant3.echeance2.prix / 100)}</span>
                            </div>
                            <div>
                                <span>{montant3.echeance3.date}</span>
                                <span className=" floatRight mr5">{euroFormate(montant3.echeance3.prix / 100)}</span>
                            </div>
                        </CardContent>
                        <CardActions className={css.center}>
                            <ListeButton variant="outlined" size="small" onClick={() => paybox(3)} color="primary">
                                Paiement
                            </ListeButton>
                        </CardActions>
                    </Card>
                </div>,
            );
        }
        return bouttonPaiement;
    }
    function calculMontant(montantTotal, tableauReglement) {
        let mntTotal = parseFloat(montantTotal) * 100;
        for (let index = 0; index < tableauReglement.length; index++) {
            mntTotal = mntTotal - Math.round(tableauReglement[index].MontantDejaRegle * 100);
        }
        return mntTotal;
    }
    async function recupDataCotisation() {
        const reglements = await GET(`/api/cotisations/liste/${user[usurpation].fk_id}?fk_anneeScolaire=${user.idAnneeScolaire}`);
        if (reglements.data.length > 0) {
            const listePaiements = await GET(`/api/reglements/cotisation/${reglements.data[0].idCotisation}`, undefined, {
                redirectOnError: false,
            });

            let mntTotal = Math.round(reglements.data[0].montantTotal * 100, 2) / 100;
            // 172.31.202.59
            mntTotal = calculMontant(mntTotal, reglements.data);

            reglements.data[0].montantTotal = mntTotal;
            setCotisation(reglements.data[0]);
            setMontant2({
                echeance1: {
                    prix: Math.round(mntTotal / 2),
                    date: date.toLocaleDateString('fr-FR'),
                },
                echeance2: {
                    prix: mntTotal - Math.round(mntTotal / 2),
                    date: new Date(date.setMonth(date.getMonth() + 1)).toLocaleDateString('fr-FR'),
                },
            });
            setMontant3({
                echeance1: {
                    prix: Math.round(mntTotal / 3),
                    date: new Date(date.setMonth(date.getMonth() - 1)).toLocaleDateString('fr-FR'),
                },
                echeance2: {
                    prix: Math.round(mntTotal / 3),
                    date: new Date(date.setMonth(date.getMonth() + 1)).toLocaleDateString('fr-FR'),
                },
                echeance3: {
                    prix: mntTotal - 2 * Math.round(mntTotal / 3),
                    date: new Date(date.setMonth(date.getMonth() + 1)).toLocaleDateString('fr-FR'),
                },
            });
            function testAffichage(tableauPaiement) {
                if (tableauPaiement.length === 0) return true;

                if (mntTotal === 0) return false;

                let result = true;
                for (let index = 0; index < tableauPaiement.length; index++) {
                    if (tableauPaiement[index].fk_mode === 1 && tableauPaiement[index].valide === 1) {
                        result = result && false;
                    }
                    if (tableauPaiement[index].fk_mode === 10) {
                        result = result && true;
                    }
                }

                return result;
            }
            return setAffichagePaiement(testAffichage(listePaiements.data));
        } else {
            const response = await GET(`/api/cotisations/cotisationPayeur/${user[usurpation].fk_id}?fk_anneeScolaire=${user.idAnneeScolaire}`);
            if (response.data.length > 0) {
                setNonPayeur(true);
            }
        }
    }
    useEffect(() => {
        if (user[usurpation].role === 'Responsable') {
            recupDataCotisation();
            paiementOuverte();
            Boutton();
        }
    }, []);
    if (
        new Date().getTime() > new Date(paiementDate.cbOuverture).getTime() &&
        new Date().getTime() < new Date(paiementDate.cb1xFermeture).getTime() &&
        affichagePaiement &&
        user[usurpation].role === 'Responsable' &&
        cotisation
    ) {
        return (
            <Paper className={[css.ml10, css.mb2, css.wMax875, css.xs_nomargin, css.p2, css.mt2]} elevation={0}>
                <div className=" flex directionColumn ">
                    <h3 className="textCenter mb2">Le paiement est ouvert jusqu'au {new Date(paiementDate.cb1xFermeture).toLocaleDateString('fr-FR')}</h3>
                    <div className="mt2 flex spaceAround">
                        <Boutton />
                    </div>
                </div>
            </Paper>
        );
    } else if (
        new Date().getTime() > new Date(paiementDate.cbOuverture).getTime() &&
        new Date().getTime() < new Date(paiementDate.cb1xFermeture).getTime() &&
        user[usurpation].role === 'Responsable' &&
        nonPayeur
    ) {
        return (
            <Paper className={[css.ml10, css.mb2, css.wMax875, css.xs_nomargin, css.p2, css.mt2]} elevation={0}>
                <div className=" flex directionColumn ">
                    <h3 className="textCenter mb2">Le paiement de la cotisation est pris en charge par un autre responsable.</h3>
                </div>
            </Paper>
        );
    } else {
        return <></>;
    }
};

export default connect(null, null)(Paybox);
