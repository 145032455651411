/* eslint-disable react-hooks/exhaustive-deps */
/**
 *
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Table, Modal } from 'antd';

import { FormControl, Checkbox, Button, FormHelperText } from '@material-ui/core';

import { GET, POST } from '../../../components/Utils/requete';
import Input from '../../../components/atoms/TextField';
import TelephoneInput from '../../../components/organism/Input/telephone';
import SelectSimple from '../../../components/organism/SelectSimple/SelectSimple';
import { genre } from '../../../components/Utils/consts';
import DateInput from '../../../components/organism/Input/date';
import DateHeureFormat from '../../../components/Utils/DateHeureFormat';
import anneeEnCour from '../../../components/Utils/requeteParametre/anneeEnCour';
import { testDroit } from '../../../components/Utils/testDroit';

import useStylesMaterielUIGlobal from '../../../styles/StyleMaterielUIGlobal';

import { Ajout_nouvel_élève, Modifier_eleve } from '../../../fonctionsRoles/eleve';
import dayjs from 'dayjs';

const input = [
    { label: 'Nom', input: 'nom', type: '' },
    { label: 'Prenom', input: 'prenom', type: '' },
    { label: 'Téléphone mobile', input: 'telephoneMobile', type: '' },
    { label: 'Date de naissance', input: 'dateNaissance', type: 'date' },
    { label: 'Date de 1ère inscription', input: 'dateInscription', type: 'date' },
    { label: 'Date de création', input: 'dateCreation', type: 'date' },
    { label: "Date d'abandon", input: 'dateAbandon', type: 'date' },
];

const GeneralEleve = (props) => {
    const [open, setOpen] = useState(false);
    let { id } = useParams();
    const history = useHistory();
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    const reducerFiltre = useSelector((state) => state.SelectSimple);
    const css = useStylesMaterielUIGlobal();

    const userR = !testDroit(user[usurpation].roleFonction, Modifier_eleve);
    const [checkBoxAbandon, setCheckBoxAbandon] = useState(false);
    const [checkBoxAdulte, setCheckBoxAdulte] = useState(false);
    const [age, setAge] = useState();
    const [errorMessage, setErrorMessage] = useState([]);
    const [droitImage, setDroitImage] = useState([]);
    const [dataInput, setDataInput] = useState({
        nom: '',
        prenom: '',
        telephoneMobile: '',
        dateNaissance: '',
        dateInscription: '',
        dateCreation: '',
        dateAbandon: null,
        dateSuppression: null,
    });

    function updateState(key, value) {
        setDataInput((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    }

    function nouvelleEleve() {
        for (let elem = 0; elem < input.length; elem++) {
            setDataInput((prevState) => ({
                ...prevState,
                [Object.keys(dataInput)[elem]]: '',
            }));
        }
        setCheckBoxAdulte(false);
        props.removeFiltre();
    }

    function childreDate(typeDate) {
        if (typeDate === 'dateNaissance') {
            return <label className="ml2"> {age} ans</label>;
        }
        if (typeDate === 'dateAbandon') {
            return (
                <div className="flex alignICenter">
                    <Checkbox
                        disabled={userR}
                        inputProps={{
                            'aria-label': 'uncontrolled-checkbox',
                        }}
                        className={css.ml2}
                        onChange={() => {
                            if (!checkBoxAbandon) {
                                updateState('dateAbandon', new Date());
                            } else {
                                updateState('dateAbandon', null);
                            }
                            setCheckBoxAbandon(!checkBoxAbandon);
                        }}
                        checked={checkBoxAbandon}
                    />
                    <label className="ml1">Abandon</label>
                </div>
            );
        }
        return <></>;
    }

    function verifData(data) {
        const erreur = [];
        for (let index = 0; index < data.length; index++) {
            if (!data[index]) {
                erreur.push('information manquante');
            } else {
                erreur.push('');
            }
        }
        setErrorMessage(erreur);
        if (erreur.includes('information manquante')) {
            props.notification({ message: 'Veuillez remplir les champs manquant' });
            return false;
        } else {
            return true;
        }
    }

    async function dataEleve() {
        const response = await GET('/api/eleves/' + id);
        setAge(Math.abs(new Date(Date.now() - new Date(response.data.dateNaissance).getTime()).getFullYear() - 1970));
        for (let elem = 0; elem < Object.keys(dataInput).length; elem++) {
            setDataInput((prevState) => ({
                ...prevState,
                [Object.keys(dataInput)[elem]]: response.data[Object.keys(dataInput)[elem]],
            }));
        }
        if (response.data.adulte === 1) {
            setCheckBoxAdulte(true);
        }
        if (response.data.dateAbandon !== null) {
            setCheckBoxAbandon(true);
        }
        props.filtre([{ Genre: response.data.fk_genre }]);
        props.addNomPage(`Profil élève - ${response.data.nom} ${response.data.prenom}`);
        droitImageAnneeEnCour();
    }
    async function droitImageAnneeEnCour() {
        const anneeEnCour = await GET(`/api/parametres/getValeur/anneeEnCours`);
        const listeDroit = await GET(`/api/droitImage/listeDroit/${id}?fk_anneeScolaire=${anneeEnCour.data.valeur}`);
        setDroitImage(listeDroit.data);
    }
    async function saveEleve() {
        const data = {
            idEleve: id === '0' ? null : id,
            nom: dataInput.nom,
            prenom: dataInput.prenom,
            fk_genre: reducerFiltre.Genre,
            dateNaissance: DateHeureFormat({ BDD: 'date', date: dataInput.dateNaissance }),
            adulte: checkBoxAdulte === true ? 1 : 0,
            dateCreation: DateHeureFormat({ BDD: 'date', date: dataInput.dateCreation }),
            dateInscription: DateHeureFormat({ BDD: 'date', date: dataInput.dateInscription }),
            dateAbandon: !dataInput.dateAbandon ? dataInput.dateAbandon : DateHeureFormat({ BDD: 'date', date: dataInput.dateAbandon }),
            telephoneMobile: dataInput.telephoneMobile,
            dateSuppression: null,
        };
        const verif = [data.fk_genre, data.nom, data.prenom, data.dateNaissance, data.dateInscription];
        if (verifData(verif)) {
            let response = await POST('/api/eleves/' + id, data);
            history.push(`/fiche-eleve/${response.data.idEleve}`);
            if (checkBoxAbandon) {
                response = await GET(`/api/eleves/${id}/SI_list`);
                const annees = await anneeEnCour();
                const SI_AnneEnCour = response.data.filter((e) => +e.fk_anneeScolaire === +annees.id);
                if (SI_AnneEnCour.length !== 0) {
                    for (let index = 0; index < SI_AnneEnCour.length; index++) {
                        SI_AnneEnCour[index].fk_statut = 6;
                        SI_AnneEnCour[index].fk_eleve = id;
                        response = await POST('/api/eleves/' + id + '/scolaritesInterne/' + SI_AnneEnCour[index].idScolarite, SI_AnneEnCour[index], {
                            redirectOnError: false,
                        });
                    }
                }
            }
            props.notification({ message: "L'élève a été enregistré", status: true });
        }
    }

    async function reactivation() {
        await GET(`/api/eleves/${id}/ractivationEleve`);
        dataEleve();
    }
    async function modificationDroitImage() {
        const anneeEnCour = await GET(`/api/parametres/getValeur/anneeEnCours`);
        let etat = 1;
        if (droitImage.length !== 0 && +droitImage[0].etat === 1) etat = 0;
        const droit = {
            fk_eleve: id,
            etat: etat,
            utilisateur: user[usurpation].idUtilisateur,
            fk_anneeScolaire: anneeEnCour.data.valeur,
        };
        const response = await POST('/api/droitImage/', droit);
        if (response.success) {
            setOpen(false);
            droitImageAnneeEnCour();
        }
    }

    useEffect(() => {
        if (id !== '0') {
            dataEleve();
        }
    }, [id]);
    const columns = [
        {
            render: (text, props) => <span>{props.etat === 1 ? <label className="cVert"> accepté </label> : <label className="cRed"> refusé </label>}</span>,
            className: 'p0_5I',
            responsive: ['sm'],
        },
        {
            render: (text, props) => <span>{dayjs(props.dateHeure).format('DD/MM/YYYY')} </span>,
            className: 'p0_5I',
            responsive: ['sm'],
        },
        {
            render: (text, props) => <span>{props.nomPrenomUtilisateur} </span>,
            className: 'p0_5I',
            responsive: ['sm'],
        },
    ];

    return (
        <div>
            <Modal
                width={'30%'}
                open={open}
                onCancel={() => setOpen(false)}
                footer={[
                    <Button danger key="back" onClick={() => setOpen(false)}>
                        Retour
                    </Button>,
                    <Button danger key="back" onClick={() => modificationDroitImage()}>
                        Valider
                    </Button>,
                ]}
            >
                <span className="fontBold">
                    Le droit à l'image sera
                    {droitImage.length !== 0 && +droitImage[0].etat === 1 ? (
                        <label className="cVert"> accepté </label>
                    ) : (
                        <label className="cRed"> refusé </label>
                    )}
                    pour l'année en cours
                </span>
            </Modal>
            {!dataInput.dateSuppression ? null : (
                <div className="mb2">
                    <p className="bgcRougeFF6347 cWhite textCenter fontSize1_1em">Ce compte est désactivé</p>
                    <div className="flex spaceAround w100">
                        <div className="flex alignICenter textRight mb1 w70">
                            <label>Date de désactivation</label>
                            <Input
                                disabled={true}
                                value={new Date(dataInput.dateSuppression).toLocaleString('fr-FR')}
                                className={[css.nomargin, css.ml30px, css.w30]}
                            />
                        </div>
                        <Button variant="outlined" size="small" onClick={() => reactivation()}>
                            Réactiver cet l'élève
                        </Button>
                    </div>
                </div>
            )}
            <h3 className="none xs_block textCenter mt5">Informations général</h3>
            <div className="flex">
                <div className="w60">
                    <div className="flex alignICenter textRight mb1">
                        <label className="w35">Genre</label>
                        <FormControl className={[css.directionRow, css.w60, css.ml30px]}>
                            <div className="flex directionColumn w50">
                                <SelectSimple disabled={userR} tableau={genre} indice={'Genre'} classe={css.w100} native={true}></SelectSimple>
                                <FormHelperText style={{ color: 'red' }}>{errorMessage[0]}</FormHelperText>
                            </div>
                            <div className="flex alignICenter">
                                <Checkbox
                                    className={css.ml2}
                                    disabled={userR}
                                    checked={checkBoxAdulte}
                                    onChange={() => {
                                        setCheckBoxAdulte(!checkBoxAdulte);
                                    }}
                                />
                                <label className="ml2">Adulte</label>
                            </div>
                        </FormControl>
                    </div>
                    {input.map((row, index) => (
                        <>
                            {row.type === '' ? (
                                <div key={index} className="flex alignICenter textRight mb1">
                                    <label className="w35">{row.label}</label>
                                    {row.input === 'telephoneMobile' ? (
                                        <>
                                            <TelephoneInput
                                                className="ml30px"
                                                value={dataInput[row.input]}
                                                onChange={(event) => {
                                                    updateState(row.input, event);
                                                }}
                                            />
                                        </>
                                    ) : (
                                        <Input
                                            key={index}
                                            value={dataInput[row.input]}
                                            className={[css.nomargin, css.ml30px, css.w30]}
                                            onChange={(event) => {
                                                updateState(row.input, row.input === 'nom' ? event.target.value.toUpperCase() : event.target.value);
                                            }}
                                            helperText={<span style={{ color: 'red' }}>{errorMessage[index + 1]}</span>}
                                        />
                                    )}
                                </div>
                            ) : (
                                <div className="flex directionColumn">
                                    <DateInput
                                        classesLabel="w35"
                                        label={row.label}
                                        disabled={row.input === 'dateAbandon' ? !checkBoxAbandon : row.input === 'dateSuppression' ? true : userR}
                                        format="dd/MM/yyyy"
                                        value={dataInput[row.input]}
                                        className={[css.nomargin, css.ml30px, css.w30]}
                                        onChange={(event) => {
                                            updateState(row.input, event === null ? null : new Date(event));
                                        }}
                                        children={childreDate(row.input)}
                                        helperText={<span style={{ color: 'red' }}>{errorMessage[index]}</span>}
                                    />
                                </div>
                            )}
                        </>
                    ))}
                </div>
                {!window.matchMedia('(max-width: 576px)').matches && (
                    <div>
                        {droitImage.length !== 0 ? (
                            <div className="flex directionColumn ">
                                {droitImage[0] && (
                                    <label className="mb15">
                                        Le droit à l'image est
                                        {+droitImage[0].etat === 1 ? <label className="cVert"> accepté </label> : <label className="cRed"> refusé </label>}
                                        pour l'année en cours
                                    </label>
                                )}
                                <div className="flex directionColumn mt15">
                                    <label>Historique</label>
                                    <Table
                                        rowClassName={(record, index) => 'nomargin'}
                                        className="nomargin"
                                        showHeader={false}
                                        pagination={false}
                                        columns={columns}
                                        dataSource={droitImage}
                                    />
                                </div>
                            </div>
                        ) : (
                            <label className="textLeft xs_fontSize0_8em"> Pas d'information pour l'année en cours</label>
                        )}
                        <Button variant="outlined" size="small" color={'primary'} className={[css.mt5, css.floatRight]} onClick={() => setOpen(true)}>
                            {droitImage.length !== 0 ? (+droitImage[0].etat === 1 ? "refuser pour l'année" : "accepter pour l'année") : "accepter pour l'année"}
                        </Button>
                    </div>
                )}
            </div>
            <div className="xs_ml5">
                <Link to="/liste-eleves">
                    <Button variant="outlined" size="small" color="secondary">
                        Retour
                    </Button>
                </Link>
                {testDroit(user[usurpation].roleFonction, Ajout_nouvel_élève) && (
                    <Link to="/fiche-eleve/0">
                        <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            className={[css.cVert, css.borderCvert, css.ml2, css.mr2]}
                            onClick={() => {
                                nouvelleEleve();
                                props.addNomPage('Profil élève - nouvel élève');
                            }}
                        >
                            Nouvel élève
                        </Button>
                    </Link>
                )}
                {testDroit(user[usurpation].roleFonction, Modifier_eleve) && (
                    <Button
                        variant="outlined"
                        size="small"
                        color={props.suppression ? 'secondary' : 'primary'}
                        className={props.className ? props.className : undefined}
                        onClick={() => {
                            saveEleve();
                        }}
                    >
                        enregistrer
                    </Button>
                )}
            </div>
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        addNomPage(nomPage) {
            dispatch({ type: 'stock', nomPage });
        },
        removeFiltre() {
            dispatch({ type: 'removeFiltre' });
        },
        filtre(filtre) {
            dispatch({ type: 'simple', filtre });
        },
        notification(note) {
            dispatch({ type: 'notification', note });
        },
    };
}

export default connect(null, mapDispatchToProps)(GeneralEleve);
